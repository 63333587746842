// ############################################################################
// Constants
// ############################################################################

$rp-alerts-width:                   22.25rem;
$rp-alerts-padding:                 .75rem;
$rp-alerts-gap:                     .75rem;

$rp-alert-gap:                      .25rem;

$rp-alert-container-padding:        .5625rem .75rem;
$rp-alert-container-gap:            .5rem;
$rp-alert-container-border-width:   $border-width;
$rp-alert-container-border-radius:  .5rem;

$rp-alert-icon-padding:             .1875rem;
$rp-alert-icon-margin:              .0625rem 0;
$rp-alert-icon-border-radius:       .25rem;

$rp-alert-text-padding:             .25rem;



// ############################################################################
// Mixins
// ############################################################################

@mixin alert-color($name, $color, $color-bg) {
  $color-hover: rgba($color, .16);

  &.rp-alert-#{$name} {
    .rp-alert-container {
      border-color: $color;
      background-color: $color-bg;

      .rp-alert-icon {
        color: $color;
        background-color: $color-hover;
      }

      .rp-alert-content {
        hr {
          border-color: $color-hover;
        }
      }

      .rp-alert-close {
        .rp-icon-btn {
          @include icon-button-style((
              color:            $color,
              background:       transparent,
              border:           transparent,
              hover-background: $color-hover,
              hover-border:     transparent
          ));
        }
      }
    }

    .rp-alert-hint {
      color: $color;
    }
  }
}



// ############################################################################
// Styles
// ############################################################################

.rp-alerts {
  display: flex;
  flex-direction: column;

  position: fixed;
  top: $rp-alerts-padding;
  right: $rp-alerts-padding;
  z-index: $zindex-popover + 1;

  max-width: subtract(100%, $rp-alerts-padding * 2);
  gap: $rp-alerts-gap;

  @media (min-width: add($rp-alerts-width, $rp-alerts-padding * 2)) {
    max-width: $rp-alerts-width;
  }

  .rp-alert {
    margin-bottom: 0;

    .rp-alert-header,
    .rp-alert-hint {
      display: none;
    }

    .rp-alert-container {
      box-shadow: $elevation-600;
    }
  }
}

body.has-header {
  .rp-alerts {
    top: add($header-height, $rp-alerts-padding);
  }
}

.rp-alert {
  display: flex;
  flex-direction: column;

  gap: $rp-alert-gap;
  margin-bottom: $rp-alerts-gap;

  .rp-alert-container {
    display: flex;
    align-items: flex-start;

    padding: $rp-alert-container-padding;
    gap: $rp-alert-container-gap;

    border-width: $rp-alert-container-border-width;
    border-style: solid;
    border-radius: $rp-alert-container-border-radius;

    color: $neutral-on-bg;
    @include font-settings($body-large);

    .rp-alert-icon {
      display: inline-flex;

      padding: $rp-alert-icon-padding;
      margin: $rp-alert-icon-margin;

      border-radius: $rp-alert-icon-border-radius;

      .icon {
        font-size: $icon-size-md;
      }
    }

    .rp-alert-close {
      margin: $rp-alert-icon-margin;
    }
  }

  .rp-alert-header {
    color: $neutral-on-bg;
    @include font-settings($title-small);
  }

  .rp-alert-hint {
    @include font-settings($label-medium);

    a {
      color: inherit;
      text-decoration: underline;
    }
  }

  @include alert-color("error", $color-error, $color-error-bg);
  @include alert-color("warning", $color-warning, $color-warning-bg);
  @include alert-color("success", $color-success, $color-success-bg);
  @include alert-color("info", $color-info, $color-info-bg);
}

.rp-alert-content {
  display: flex;
  flex-direction: column;
  flex: 1;
  word-break: break-word;

  .rp-alert-text {
    padding: $rp-alert-text-padding 0;
  }

  .rp-qc {
    flex: 1;
  }

  a {
    color: inherit;
    @include element-underlined();
  }

  ul {
    list-style: inside;

    margin: 0;
    padding: 0;
  }

  hr {
    margin: $rp-alert-text-padding * 2 0;
  }
}
