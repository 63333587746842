$rp-hover-actions-offset:             .25rem;
$rp-hover-actions-padding:            .25rem .5rem;
$rp-hover-actions-gap:                .5rem;
$rp-hover-actions-border:             $border-width solid $border-color;
$rp-hover-actions-border-radius:      .25rem;
$rp-hover-actions-background:         $neutral-surface;
$rp-hover-actions-elevation:          $elevation-600;



@mixin hover-actions() {
  .hover-actions {
    display: none;

    position: absolute;
    top: $rp-hover-actions-offset;
    right: $rp-hover-actions-offset;
    z-index: 2;

    padding: $rp-hover-actions-padding;
    gap: $rp-hover-actions-gap;

    border: $rp-hover-actions-border;
    border-radius: $rp-hover-actions-border-radius;
    background: $rp-hover-actions-background;
    box-shadow: $rp-hover-actions-elevation;
  }

  &:hover {
    .hover-actions {
      display: flex;
    }
  }
}

@mixin font-settings($font-map) {
  @each $property, $value in $font-map {
    #{$property}: $value;
  }
}

@mixin hide-scrollbar() {
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

@mixin hide-caret() {
  &::before {
    display: none !important;
  }
  &::after {
    display: none !important;
  }
}

@mixin element-underlined() {
  & {
    text-decoration: underline;
  }

  &:hover {
    text-decoration: none;
  }
}
